import {Form, InputNumber, Select} from 'antd';
import SubmitButton from 'components/Startup/components/SubmitButton';
import styles from './baseInvestmentDetails.module.scss';
import {portfolioStrategyData} from 'pages/campaigns/campaignData';
import {Fragment, useEffect, useState} from 'react';
import IpoFeeCard from 'pages/campaigns/new/screens/ipo/ipoFeeCard/IpoFeeCard';

const BaseInvestmentDetails = ({onSubmit, existingValues}) => {
  const [form] = Form.useForm();
  const {Option} = Select;
  const [ipoFeesValues, setIpoFeesValues] = useState([]);

  useEffect(() => {
    console.log(existingValues.investmentRisk);
    if (existingValues && existingValues.investmentRisk) {
      form.setFieldsValue({...existingValues});
      setIpoFeesValues(existingValues.feeSchedule || []);
    }
  }, [existingValues]);

  const handleChangeIpoFeesValues = (index, event) => {
    const newFormValues = [...ipoFeesValues];
    newFormValues[index][event.target.name] = event.target.value;
    setIpoFeesValues(newFormValues);
  };

  const handleChangeIpoFeesType = (index, event) => {
    const newFormValues = [...ipoFeesValues];
    newFormValues[index].feeType = event;
    setIpoFeesValues(newFormValues);
  };

  const handleAddIpoFees = () => {
    setIpoFeesValues([
      ...ipoFeesValues,
      {
        feeName: '',
        feeType: '',
        fixedFeeAmount: '',
        variableFeeAmount: '',
      },
    ]);
  };

  const handleDeleteIpoFees = id => {
    const filteredIpoFee = ipoFeesValues.filter((_, index) => index !== id);
    setIpoFeesValues(filteredIpoFee);
  };

  return (
    <div className={styles.container}>
      <Form
        form={form}
        onFinish={values =>
          onSubmit({
            ...values,
            feeSchedule: ipoFeesValues,
          })
        }
        layout="vertical">
        <div>
          <h2>Portfolio Strategy</h2>
          <div>
            <Form.Item
              style={{width: 450, marginTop: 10}}
              name="recommendedPortfolioStrategy"
              label="Recommended Portfolio Strategy">
              <Select placeholder="Select a recommended portfolio strategy">
                {portfolioStrategyData?.map(data => (
                  <Option key={data.value}>
                    <span>{data.label}</span>
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              rules={[{required: true, message: 'This is a Required Field.'}]}
              style={{width: 450, marginBottom: 10}}
              name="investmentRisk"
              label="Investment Risk">
              <Select placeholder="Select an Investment Risk">
                <Option value="LOW">Low</Option>
                <Option value="MEDIUM">Medium</Option>
                <Option value="HIGH">High</Option>
              </Select>
            </Form.Item>
          </div>
        </div>
        <div>
          <h2>Investment Details in Asset Currency</h2>
        </div>
        <div>
          <div className={styles.form}>
            <Form.Item
              className={styles['field-width']}
              name="fundingTarget"
              label="Funding Target">
              <InputNumber
                placeholder="Enter Funding Target Amount"
                className={styles['input-number']}
                formatter={value =>
                  `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                }
                parser={value => value.replace(/\$\s?|(,*)/g, '')}
              />
            </Form.Item>
            <Form.Item
              className={styles['field-width']}
              name="companyValuation"
              label="Valuation">
              <InputNumber
                placeholder="Enter Company Valuation Amount"
                className={styles['input-number']}
                formatter={value =>
                  `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                }
                parser={value => value.replace(/\$\s?|(,*)/g, '')}
              />
            </Form.Item>
            <Form.Item
              name="minimumInvestmentAmount"
              label="Minimum Investment"
              rules={[
                {
                  validator: (_, value) =>
                    value > form.getFieldValue('fundingTarget')
                      ? Promise.reject(
                          new Error(
                            'This must be Less than the Funding Target',
                          ),
                        )
                      : Promise.resolve(),
                },
              ]}>
              <InputNumber
                placeholder="Enter amount"
                className={styles['input-number']}
                formatter={value =>
                  `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                }
                parser={value => value.replace(/\$\s?|(,*)/g, '')}
              />
            </Form.Item>

            <Form.Item
              className={styles['field-width']}
              name="maximumFundingTarget"
              label="Maximum Funding Target"
              rules={[
                {
                  validator: (_, value) =>
                    value < form.getFieldValue('fundingTarget')
                      ? Promise.reject(
                          new Error(
                            'This must be GREATER than the Funding Target',
                          ),
                        )
                      : Promise.resolve(),
                },
              ]}>
              <InputNumber
                placeholder="Enter amount"
                className={styles['input-number']}
                formatter={value =>
                  `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                }
                parser={value => value.replace(/\$\s?|(,*)/g, '')}
              />
            </Form.Item>
            <Form.Item
              className={styles['field-width']}
              name="minimumFundingTarget"
              label="Minimum Funding Target"
              rules={[
                {
                  validator: (_, value) =>
                    value > form.getFieldValue('fundingTarget')
                      ? Promise.reject(
                          new Error(
                            'This must be LESS than the Funding Target',
                          ),
                        )
                      : Promise.resolve(),
                },
              ]}>
              <InputNumber
                placeholder="Enter amount"
                className={styles['input-number']}
                formatter={value =>
                  `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                }
                parser={value => value.replace(/\$\s?|(,*)/g, '')}
              />
            </Form.Item>

            <Form.Item
              className={styles['field-width']}
              label="Price Per Security"
              name="pricePerSecurity">
              <InputNumber
                placeholder="Enter amount"
                className={styles['input-number']}
                formatter={value =>
                  `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                }
                parser={value => value.replace(/\$\s?|(,*)/g, '')}
              />
            </Form.Item>
          </div>
          <h2>Fee Schedule</h2>
          {ipoFeesValues?.map((data, index) => (
            <Fragment key={index}>
              <IpoFeeCard
                feeName={data.feeName || ''}
                feeType={data.feeType}
                fixedFeeAmount={data.fixedFeeAmount}
                type="COMPANY_STOCK"
                variableFeeAmount={data.variableFeeAmount}
                handleChangeIpoFeesValues={e =>
                  handleChangeIpoFeesValues(index, e)
                }
                handleChangeIpoFeesType={e => handleChangeIpoFeesType(index, e)}
                handleDeleteIpoFees={e => handleDeleteIpoFees(index, e)}
              />
            </Fragment>
          ))}
          <div onClick={handleAddIpoFees} className={styles['add-button']}>
            <h2> Add Fee Field</h2>
          </div>
          <SubmitButton label="Save & Continue" />
        </div>
      </Form>
    </div>
  );
};

export default BaseInvestmentDetails;
