import {DatePicker, Form, Select, Switch} from 'antd';
import SubmitButton from 'components/Startup/components/SubmitButton';
import moment from 'moment';
import styles from './settings.module.scss';
import {useEffect} from 'react';
import {getUserTimezone} from 'utils/helper';

export const BaseSettings = ({handleSave, existingValues}) => {
  const [form] = Form.useForm();
  const {Option} = Select;

  useEffect(() => {
    if (existingValues && existingValues?.startDate) {
      form.setFieldsValue({
        publicAudience:
          existingValues?.publicAudience ||
          existingValues?.settings?.publicAudience,
        isPaused:
          existingValues?.isPaused || existingValues?.settings?.isPaused,
        isTimeSensitive: existingValues?.isTimeSensitive,
        isVisible:
          existingValues?.isVisible || existingValues?.settings?.isVisible,
        shouldNotifyUsersOnLaunch:
          existingValues?.shouldNotifyUsersOnLaunch ||
          existingValues?.settings?.shouldNotifyUsersOnLaunch,
      });
    }
  }, [existingValues]);

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <h1>Settings</h1>
      </div>
      <div>
        <Form form={form} onFinish={handleSave} layout="vertical">
          <Form.Item name="publicAudience" label="Public Audience">
            <Select
              style={{width: 796}}
              mode="tags"
              allowClear
              placeholder="Select a Public Audience">
              <Option key="INVITED_USERS">Invited Users</Option>
              <Option key="MEMBERS_INVITED_GROUPS">
                Members of Invited Groups
              </Option>
            </Select>
          </Form.Item>
          <div className={styles.date}>
            <Form.Item
              label="Start Date"
              name="startDate"
              rules={[{required: true, message: 'Please select a status'}]}
              initialValue={
                existingValues?.startDate
                  ? moment(existingValues.startDate, 'YYYY-MM-DD HH:mm:ss')
                  : ''
              }
              className={styles['form-item']}>
              <DatePicker
                placeholder="Start Date"
                showTime={{
                  defaultValue: moment('00:00', 'HH:mm:ss'),
                }}
                defaultValue={
                  existingValues?.startDate
                    ? moment(existingValues.startDate, 'YYYY-MM-DD HH:mm:ss')
                    : ''
                }
                format={'YYYY-MM-DD HH:mm:ss'}
                style={{width: 340}}
              />
            </Form.Item>
            <Form.Item
              label="Finish Date"
              name="endDate"
              rules={[{required: true, message: 'Please select a status'}]}
              className={styles['form-item']}
              initialValue={
                existingValues?.endDate
                  ? moment(existingValues.endDate, 'YYYY-MM-DD HH:mm:ss')
                  : ''
              }>
              <DatePicker
                name="endDate"
                defaultValue={
                  existingValues?.endDate
                    ? moment(existingValues.endDate, 'YYYY-MM-DD HH:mm:ss')
                    : ''
                }
                placeholder="Finish Date"
                renderExtraFooter={() => (
                  <div style={{textAlign: 'center'}}>
                    <p>Your timezone is: {getUserTimezone()}</p>
                  </div>
                )}
                showTime={{
                  defaultValue: moment('00:00', 'HH:mm:ss'),
                }}
                format={'YYYY-MM-DD HH:mm:ss'}
                style={{width: 340}}
              />
            </Form.Item>
          </div>
          <div>
            <Form.Item
              name="isVisible"
              label=" Visibility (Disabling this will HIDE this  from all but Internal Team Members)">
              <Switch defaultChecked={existingValues?.isVisible} />
            </Form.Item>

            <Form.Item name="isPaused" label="Paused">
              <Switch defaultChecked={existingValues?.isPaused} />
            </Form.Item>
            <Form.Item
              name="shouldNotifyUsersOnLaunch"
              label="Notify Users on Launch">
              <Switch
                defaultChecked={existingValues?.shouldNotifyUsersOnLaunch}
              />
            </Form.Item>
            <Form.Item
              rules={[{required: true, message: 'This is a required field'}]}
              name="isTimeSensitive"
              label="Time Sensitive">
              <Switch defaultChecked={existingValues?.isTimeSensitive} />
            </Form.Item>
          </div>
          <SubmitButton label="Continue" />
        </Form>
      </div>
    </div>
  );
};
